<template>
  <div>
    <component :is="'b-card'">
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-form-group :label="$t('Selected Category')" label-for="category">
            <b-form-input id="category" v-model="traitPopulation.populationName" readonly/>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12" md="4" lg="4">
          <b-form-group :label="$t('Selected Feature')" label-for="featureName">
            <b-form-input id="featureName" v-model="traitFeature.name" readonly/>
          </b-form-group>
        </b-col> -->
      </b-row>
    </component>
    <component :is="'b-card'">
      <div class="d-flex align-items-center justify-content-end mb-2">
        <b-button
          variant="warning"
          class="mb-sm-0 mr-0 mr-sm-1"
          @click="addNewTrait()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          Add New
        </b-button>
        <b-button
          variant="primary"
          class="mb-sm-0 mr-0 mr-sm-1"
          @click="save()"
          size="sm">
          <b-spinner
            v-if="$store.getters.getLoader"
            class="mr-1"
            small
            variant="light"
          />
          <feather-icon
            v-if="!$store.getters.getLoader"
            icon="SaveIcon"
            class="mr-50"
            size="16"
          />
          {{ $t('Save Changes') }}
        </b-button>
      </div>
      <b-table
        ref="refListTable"
        :fields="fields"
        :items="traitFrequencyList"
      >

        <template #cell(traitFeatureId)="data">
          <v-select
            v-model="data.item.traitFeatureId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="traitFeatures"
            :reduce="(val) => val.id"
            :clearable="true"
            label="name"
            input-id="traitFeatureId"
            placeholder="Select Feature"
          />
        </template>

        <template #cell(traitMarkerId)="data">
          <v-select
            v-model="data.item.traitMarkerId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="markers"
            :reduce="(val) => val.id"
            :clearable="true"
            label="markerCode"
            input-id="traitMarkerId"
            placeholder="Select Marker"
          />
        </template>

        <template #cell(traitGenotypeId)="data">
          <v-select
            v-model="data.item.traitGenotypeId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="genotypes"
            :reduce="(val) => val.id"
            :clearable="true"
            label="genotypeCode"
            input-id="traitGenotypeId"
            placeholder="Select Genotype"
          />
        </template>

        <template #cell(frequencyRatio)="data">
          <b-form-input id="frequencyRatio" v-model="data.item.frequencyRatio" />
        </template>

        <template #cell(actions)="data">
          <div class="text-right">
            <feather-icon
              :id="`item-row-${data.item.id}-remove-icon`"
              @click="deleteItem(data.item)"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>
      </b-table>
    </component>
  </div>
</template>

<script>
import { required, min, max } from '@validations';

// Alert fade
import { heightFade } from '@core/directives/animations';

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Alert
import AlertService from '@/common/alert.service';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';

export default {
  components: {
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      traitPopulation: {},
      traitFeatures: [],
      markers: [],
      genotypes: [],

      traitFrequencyList: [],
      fields: [
        {
          key: 'traitFeatureId',
          label: 'Trait Feature',
        },
        {
          key: 'traitMarkerId',
          label: 'traitMarker',
        },
        {
          key: 'traitGenotypeId',
          label: 'traitGenotype',
        },
        {
          key: 'allele',
          label: 'allele',
        },
        {
          key: 'frequencyRatio',
          label: 'Ratio',
        },
        { 
          key: 'actions', 
          class: 'text-right',
        },
      ],

      modalObject: {
        id: {},
        showModal: false,
      },
    };
  },

  methods: {
    addNewTrait() {
      this.traitFrequencyList.push({
        id: 0,
        traitPopulationId: null,
        traitFeatureId: null,
        traitMarkerId: null,
        traitGenotypeId: null,
        allele: null,
        frequencyRatio: 0,
        isActive: true,
      });
    },

    deleteItem(item) {
      AlertService.confirmDelete(this, () => {
        const index = this.traitFrequencyList.indexOf(item);
        if (index > -1) { 
          this.traitFrequencyList.splice(index, 1);
        }

        if (item.id) {
          this.$store
            .dispatch('traitFrequencyManage/remove', { id: item.id })
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
              } else {
                AlertService.error(this, response.message);
              }
            });
        } else {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: '' },
          });
        }
      });
    },

    save() {
      let control = true;
    
      this.traitFrequencyList.forEach(item => {
        item.traitPopulationId = this.traitPopulation.id;

        if (!item.traitFeatureId || !item.traitMarkerId || !item.traitGenotypeId) {
          control = false;
        }
      });

      if (control === false) {
        AlertService.warning(this, 'Please fill in all fields.');
        return;
      }

      this.$store
        .dispatch('traitFrequencyManage/save', { traitFrequencies: this.traitFrequencyList })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });

              this.fetchTraitFrequencies();
            } else {
              AlertService.error(this, response.message);
            }
          });
    },

    fetchTraitFrequencies() {
      const paramId = this.$router.currentRoute.params.populationId;
      if (parseInt(paramId, 10) !== 0) {
        this.$store
          .dispatch('traitFrequencyManage/fetch', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.traitPopulation = response.result.traitPopulation;
              this.traitFrequencyList = response.result.traitFrequencyList;
            } else {
              AlertService.error(this, response.message);
            }
          });
      }
    },

    loadFeatures() {
      this.$store
        .dispatch('traitFeatureManage/fetchAllList', {
          featureId: this.$router.currentRoute.params.featureId,
        })
        .then(response => {
          if (response.statusCode === 200) {
            this.traitFeatures = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    loadMarkers() {
      this.$store
        .dispatch('traitMarkerManage/fetchAllList')
        .then(response => {
          if (response.statusCode === 200) {
            this.markers = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    loadGenotypes() {
      this.$store
        .dispatch('traitGenotypeManage/fetchAllList')
        .then(response => {
          if (response.statusCode === 200) {
            this.genotypes = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },
  },

  created() {
    this.fetchTraitFrequencies();
    this.loadFeatures();
    this.loadMarkers();
    this.loadGenotypes();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
